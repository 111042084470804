import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-blog-starter/src/templates/single-post.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p" {...{
        "className": "firstword"
      }}>{`Ism Manqoos`}</strong>{` Or `}<strong parentName="p" {...{
        "className": "firstword"
      }}>{`الاسم منقوص`}</strong>{` are those nouns which has ي at the end and before it is a Kasra.  `}</p>
    <blockquote>
      <p parentName="blockquote">{`كل اسم آخره ياء (خفيفة) قبلها كسرة (here خفيفة means no Shadda)`}</p>
    </blockquote>
    <br />
    <p><strong parentName="p">{`Examples`}</strong></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`القاضِي`}</strong>{` is Ism Manqoos`}</li>
      <li parentName="ul"><strong parentName="li">{`ظَبْي`}</strong>{` is NOT Ism Manqoos because we have sukon before ي`}</li>
      <li parentName="ul"><strong parentName="li">{`مصرِيّ`}</strong>{` is NOT Ism Manqoos because we have sukon before ي as `}<strong parentName="li">{`يْ ي`}</strong></li>
    </ul>
    <h2>{`ما إعراب الاسم منقوص`}</h2>
    <p>{`Ism Manqoos are nouns in Arabic grammar having special declension/case in Raf, Nasb and Jar form. Rules of which are as follows:`}</p>
    <h3>{`Thumb Rule in Ism Manqoos`}</h3>
    <ul>
      <li parentName="ul">{`When Ism Manqoos is used in Raf and Jar form, its case ending is `}<strong parentName="li">{`estimated`}</strong>{`.`}</li>
      <li parentName="ul">{`When Ism Manqoos is used in Nasb form, its case ending is `}<strong parentName="li">{`visible`}</strong>{`.`}</li>
    </ul>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p" {...{
          "className": "bg-yellow"
        }}>{`تقدر`}</strong>{` العلامة في حالة الرفع وحالة الجر و `}<strong parentName="p" {...{
          "className": "bg-yellow"
        }}>{`تظهر`}</strong>{` العلامة في حالة النصب`}</p>
    </blockquote>
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "351px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "79.75460122699387%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAYAAAAWGF8bAAAACXBIWXMAAAsTAAALEwEAmpwYAAACSUlEQVQ4y42UDVPaQBCG/f+/wXam1uJUUAGBlg8BlRaxVgtiRSytfAgaEoKIRZNLnl6CYlAcuzM7u5fdvPvu7d3N4RHbtifWFALTHKthPvFlDE+uV+a8YA+xy75BTbFo6FDXoNnH9R1tSv11KVBkzuuArrVo9Syy+3XWMwdEs0UW19KE0/vuOpj8Rq7Uoa0LJ/t1QCRgUxPkjxQS+RNyxRbRrUPXX88UiW6W+FJWOO+J/2l5HNSuDZqS5cUAVxuqSb1roAzHa6egfv1Ky/dR7IlrSbIWQpjoeo9+X8cw7ibfZ4E9Y+gdzsN6MBi403Xk6upqKm8mw2kge6r10WjEcDicJBuG4RZ4Kd+xcy8Fx4z6z5g4RW5ubmZ0ZU8zfCqqqspWzektvt9hp3VN01xfiPHxmWIohIXS6LGXK1Iq/KRa/E0uVXDt8f4pjUqHgTZEa0ug5gDlTKdyUJv4aquPJazHodz+vePssM2nlRS+N36Cvij+d2ukIzl888uU81X3x6PdKoGFEDF/kmzsK4lghs+rG5TyFYThAbwbyXN3fIFWv+b8pEs0kCCyHKcgb4Z6NqBT1VDqPcq7J7LgMh/mP5IMZ9mIbLHmi/B9qyQZ2o+Axq1JLl4gFkiSCm8S9SfwLwRJhTYly23iq2k6NZXDnQqB9yGW3gZYWQyTT+2xJDvaju9gmR5AB/1Hvuwyc6omQhliKwnyG3vkkgUi/jhqW6crX4dWRaF9qtKudmU38gpKe/FHQ9yf1RenPEsseUNMw/SocO3DQBysf1dRtppXtakPAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Ism Manqoos",
          "title": "Ism Manqoos",
          "src": "/static/46a5717cf9f824aeeee4bb2131173ff2/7c2a6/ism-manqoos.png",
          "srcSet": ["/static/46a5717cf9f824aeeee4bb2131173ff2/222b7/ism-manqoos.png 163w", "/static/46a5717cf9f824aeeee4bb2131173ff2/ff46a/ism-manqoos.png 325w", "/static/46a5717cf9f824aeeee4bb2131173ff2/7c2a6/ism-manqoos.png 351w"],
          "sizes": "(max-width: 351px) 100vw, 351px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <p><strong parentName="p">{`Without ال OR إضافة`}</strong></p>
    <ul>
      <li parentName="ul"><strong parentName="li" {...{
          "className": "heading1"
        }}>{`Raf`}</strong>{`: جاء    قا`}<strong parentName="li">{`ضٍ`}</strong>
        <blockquote parentName="li">
          <p parentName="blockquote"><strong parentName="p" {...{
              "className": "arabic irab"
            }}>{`قاضٍ`}</strong>{`
فاعل مرفوع وعلامة رفعه الضمة المقدرة`}</p>
        </blockquote>
      </li>
    </ul>
    <br />
    <ul>
      <li parentName="ul"><strong parentName="li" {...{
          "className": "heading1"
        }}>{`Nasb`}</strong>{`  رأيت  قاض`}<strong parentName="li">{`يًا`}</strong>
        <blockquote parentName="li">
          <p parentName="blockquote"><strong parentName="p" {...{
              "className": "arabic irab"
            }}>{`قاضيًا`}</strong>{`
مفعول به منصوب وعلامة نصبه الفتحة الظاهرة`}</p>
        </blockquote>
      </li>
    </ul>
    <br />
    <ul>
      <li parentName="ul"><strong parentName="li" {...{
          "className": "heading1"
        }}>{`Jar`}</strong>{` مررت  بقا`}<strong parentName="li">{`ضٍ`}</strong>{`   `}
        <blockquote parentName="li">
          <p parentName="blockquote"><strong parentName="p" {...{
              "className": "arabic irab"
            }}>{`بقاضٍ`}</strong>{`
اسم مجرور وعلامة جره الکسره المقدرة`}</p>
        </blockquote>
      </li>
    </ul>
    <br />
    <p><strong parentName="p">{`With ال OR إضافة`}</strong></p>
    <ul>
      <li parentName="ul"><strong parentName="li" {...{
          "className": "heading1"
        }}>{`Raf`}</strong>{`: جاء القاض`}<strong parentName="li">{`ي`}</strong>{` OR جاء قاض`}<strong parentName="li">{`ي`}</strong>{` المدينة`}
        <blockquote parentName="li">
          <p parentName="blockquote"><strong parentName="p" {...{
              "className": "arabic irab"
            }}>{`القاضي`}</strong>{`
فاعل مرفوع وعلامة رفعه الضمة المقدرة`}</p>
        </blockquote>
      </li>
    </ul>
    <br />
    <ul>
      <li parentName="ul"><strong parentName="li" {...{
          "className": "heading1"
        }}>{`Nasb`}</strong>{`  رأيت القاض`}<strong parentName="li">{`يَ`}</strong>{` OR رأيت قاض`}<strong parentName="li">{`يَ`}</strong>{` المدينة`}
        <blockquote parentName="li">
          <p parentName="blockquote"><strong parentName="p" {...{
              "className": "arabic irab"
            }}>{`القاضيَ`}</strong>{`
مفعول به منصوب وعلامة نصبه الفتحة الظاهرة`}</p>
        </blockquote>
      </li>
    </ul>
    <br />
    <ul>
      <li parentName="ul"><strong parentName="li" {...{
          "className": "heading1"
        }}>{`Jar`}</strong>{` مررت بالقاض`}<strong parentName="li">{`ي`}</strong>{` OR مررت بقاض`}<strong parentName="li">{`ي`}</strong>{` المدينة`}
        <blockquote parentName="li">
          <p parentName="blockquote"><strong parentName="p" {...{
              "className": "arabic irab"
            }}>{`القاضي`}</strong>{`
اسم مجرور وعلامة جره الکسره المقدرة`}</p>
        </blockquote>
      </li>
    </ul>
    <h2>{`Reference`}</h2>
    <p><a parentName="p" {...{
        "href": "https://www.qutoofacademy.com/",
        "rel": "nofollow noopener"
      }}>{`Qutoof Academy`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      